@import "@/assets/scss/style.scss";

.wrapperPaymentDetails {
    .row {
        display: flex;
        margin-bottom: 30px;
        align-items: flex-end;

        & > *:last-child {
            flex: 1 0 50%;
        }

        & > * {
            flex: 1 1 50%;
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 750px) {
    .wrapperPaymentDetails .payment-second-div {
        flex-direction: column;
        align-items: flex-start;

        & > div {
            margin-bottom: 1.5rem;
        }
    }
}
