@import "@/assets/scss/style.scss";

.productSearchWrapper {
    &::v-deep {
        .ant-select-selection {
            border-radius: 2px;
        }

        .ant-select-arrow {
            line-height: 0;
            vertical-align: 0;
            font-size: 14px;
            color: $grey-9;

            svg {
                transform: rotate(0deg);
            }
        }
    }
}
