@import "@/assets/scss/style.scss";

.productsAndActionsContainerWrapper {
    position: relative;
    border: 1px solid $grey-4;
    background-color: $white-two;
    border-radius: 10px;
    padding: 24px;

    .topBar {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .titleWrapper {
        display: flex;
    }

    .title {
        color: $grey-9;
        margin-right: 10px;
    }

    .title-icon {
        font-size: 18px;
        color: $grey-7;
    }

    &.isLoading {
        opacity: 0.6;

        &::v-deep {
            .ant-spin {
                display: flex;
                position: absolute;
                justify-content: center;
                align-items: center;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 50;
            }
        }
    }
}
