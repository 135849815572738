@import "@/assets/scss/style.scss";

.ant-row {
    padding: 5px 0;
}
.wrapper {
    padding: 2px;

    &::v-deep {
        .ant-upload.ant-upload-drag {
            border: none;
            background: transparent;

            .ant-upload-text {
                @extend .u-b1;
                color: $grey-7;
            }
        }
    }
}

.attachment-button {
    display: flex;
    align-items: center;
}

.text-dots {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 220px;
}

.attachments__upload-overlay {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(2px);
    z-index: 100;

    .prograss-bar__wrapper {
        width: 70%;
        margin-bottom: 15rem;
    }
}
