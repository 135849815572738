@import "@/assets/scss/style.scss";

.totalPriceRowWrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    width: 100%;

    &.isEditing {
        padding-right: 40px;
    }

    .price {
        color: $grey-9;
    }

    .label {
        color: $grey-8;
    }

    .price-wrapper {
        min-width: 412px;
        display: flex;
        justify-content: space-between;
    }

    @media only screen and (max-width: 1201px) {
        .price-wrapper {
            min-width: 150px;
        }
    }
}

::v-deep .u-b2--,
.p-datatable .p-datatable-thead > tr th {
    padding: 0.5rem 0.5rem;
    text-align: right;
}

.u-b0.price {
    text-align: right;
    padding: 0.5rem 0.5rem;

    &:first-child {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }
}
