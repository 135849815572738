@import "@/assets/scss/style.scss";

.productSelectorWrapper {
    display: flex;
    flex-wrap: wrap;

    .product {
        display: block;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
        margin-right: 16px;
        max-width: 92px;

        &:last-child {
            margin-right: 0;
        }
    }

    .image {
        display: block;
        max-width: 92px;
        border-radius: 4px;

        &.empty {
            width: 92px;
            height: 92px;
            background-color: $grey-three;
        }
    }

    .title {
        margin-top: 6px;
        text-align: center;
        word-break: break-word;
        overflow-wrap: break-word;
    }
}
