@import "@/assets/scss/style.scss";

@media (max-width: 600px) {
    .installationAndDeliveryDetailsForm .wrapperInstallationAndDeliveryDetails {
        .row-2,
        .row-3 {
            flex-direction: column;
            align-items: flex-start;

            & > div {
                margin-bottom: 1rem;
            }
        }
    }
}
