@import "@/assets/scss/style.scss";

.wrapperProjectTabOffer {
    padding-bottom: 40px;

    & > * {
        margin-bottom: 28px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &::v-deep {
        .buttonIcon {
            padding: 8px;
            border: 1px solid $grey-1;
            border-radius: 2px;
            color: $grey-9;
            cursor: pointer;
            background-color: transparent;
            transition: background-color 0.2s linear, color 0.2s linear;

            &:hover {
                background-color: $grey-0;
            }

            &.is-disabled {
                cursor: not-allowed;
                opacity: 0.6;

                &:hover {
                    background-color: transparent;
                    color: $grey-9;
                }
            }

            &--red {
                &:hover {
                    color: $ant-red;
                }
            }
        }

        .productsActionsWrapper button.button-container {
            @include flex-center;
            padding: 0 8px;
            transition: all 0.5s ease-in;

            svg {
                transition: all 0.5s ease-in;
            }

            &:hover svg path {
                fill: rgb(71, 171, 253);
            }
        }

        .button-disabled svg {
            opacity: 0.6;
        }
    }

    .offerBottomBarWrapper {
        margin-bottom: 0;
    }
}
