@import "@/assets/scss/style.scss";

.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.price-details-text {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: $slate-grey !important;
    &.th {
        color: #8c8c8c;
    }
}
.flex-center {
    display: flex;
    justify-content: center;
}

.co-product-in-offer {
    &__expandedInfo {
        color: $dark;

        &:last-child {
            width: 200px;
            display: inline-block;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        &__custom {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            font-size: 12px;
            color: $grey-7;
            padding: 24px 0;

            &__first {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                div {
                    display: flex;
                    flex: 1 1 auto;
                    padding: 0 16px;
                    width: 120px;
                }

                pre {
                    padding: 0 16px;
                }

                .text-left {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                .text-right {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }

                &__last {
                    display: flex;
                    justify-content: flex-end;

                    > div {
                        display: flex;
                        justify-content: flex-end;
                    }

                    &__inner {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-end;
                        width: 100%;

                        .text-right {
                            display: flex;
                            justify-content: flex-end;
                        }

                        > div {
                            display: flex;
                            width: 30%;
                            padding: 0 16px;
                        }
                    }
                }
            }
        }

        pre {
            font-family: 'Roboto-Regular', 'Arial', sans-serif;
            line-height: 14px;
            margin-bottom: 0;
            color: $dark;
        }
    }

    &__expandedInfoRow {
        display: flex;
        justify-content: space-between;
        text-align: right;
        margin-bottom: 6px;
        align-items: center;

        p {
            width: 100%;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__price-details {
        width: 100%;

        &__custom {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            font-size: 12px;
            color: $grey-7;

            &__first {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                width: fit-content;

                > div {
                    display: flex;
                    flex: 1 1 auto;
                    padding: 0 16px;
                    width: 264px;
                }

                .text-left {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                .text-right {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }

                &__last {
                    display: flex;
                    justify-content: flex-end;

                    > div {
                        display: flex;
                        padding: 0;
                    }

                    &__inner {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;

                        .text-right {
                            display: flex;
                            justify-content: flex-end;
                        }

                        > div {
                            display: flex;
                            width: 170px;
                            padding: 0px;

                            &:first-child {
                                justify-content: flex-end;
                            }

                            &:last-child {
                                justify-content: flex-end;
                            }
                        }
                    }
                }
            }
        }

        &__content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            height: 30px;
            font-size: 12px;
            font-weight: 500;
            color: $slate-grey;

            &__first {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                width: fit-content;

                > div {
                    display: flex;
                    padding: 0 16px;
                    align-items: center !important;
                    width: 264px;
                }

                .text-left {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                .text-right {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }

                &__last {
                    display: flex;
                    justify-content: flex-end;

                    > div {
                        display: flex;
                        padding: 0;
                    }

                    .text-right {
                        display: flex;
                        justify-content: flex-end;
                        padding: 0 !important;
                    }

                    &__inner {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;

                        > div {
                            display: flex;
                            width: 170px;
                            padding: 0 16px;

                            &:first-child {
                                justify-content: flex-end;
                            }

                            &:last-child {
                                justify-content: flex-end;
                            }
                        }
                    }
                }
            }
        }
    }

    &__offer-item-price-manipulation {
        padding-left: 16px;
    }

    &__price-manipulation-icon {
        margin-right: 4px;
    }

    &__offer-item-price-manipulation-action {
        cursor: pointer;
        border-radius: 50%;
        padding: 4px;
        background-color: transparent;
        transition: background-color 0.2s linear, color 0.2s linear;
        color: $blue-6;

        &:hover {
            color: $grey-9;
        }

        &--red {
            &:hover {
                color: red;
            }
        }
    }

    &__price-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__quantity-and-price_wrapper {
        min-width: 70px;
        margin: 0 20px;
        display: inline-block;
    }

    &__price-per-item {
        min-width: 40px;
        display: inline-block;
    }

    &__price-amount {
        min-width: 50px;
        display: inline-block;
    }

    &__button-icon {
        border: 1px solid $grey-1;
        border-radius: 2px;
        padding: 8px;
        cursor: pointer;
        background-color: transparent;
        transition: background-color 0.2s linear, color 0.2s linear;
    }

    &__actions {
        button {
            transition: opacity 0.2s ease-in-out;

            &.is-selected-action {
                opacity: 0.8;

                &.delete-button {
                    &::v-deep {
                        svg {
                            g {
                                stroke: $dull-red !important;
                            }
                        }
                    }
                }
            }

            &:disabled {
                pointer-events: none;
                opacity: 0.4;
            }
        }
    }
}

::v-deep .p-datatable-wrapper {
    overflow-y: auto;
    max-height: 25rem;
}

@media only screen and (max-width: 1201px) {
    ::v-deep table {
        min-width: 100%;
        overflow-x: scroll;
    }

    ::v-deep .p-datatable-wrapper {
        overflow-x: scroll;
    }

    ::v-deep table td {
        min-width: 150px !important;
        width: 150px !important;
    }

    ::v-deep table th {
        min-width: 150px !important;
        width: 150px !important;
    }
}

.tooltip-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.p-datatable th[class*='text-right'] .p-column-header-content {
    justify-content: flex-end;
}
