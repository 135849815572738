@import "@/assets/scss/style.scss";

.productAndMaterialSearchWrapper {
    display: flex;
    flex-direction: column;

    .searchWrapper {
        display: flex;
        margin-top: 16px;
    }

    .radioGroup {
        display: flex;
        margin-left: 4px;

        label {
            &:first-child {
                border-radius: 2px 0 0 2px;
            }

            &:nth-child(2) {
                border-radius: 0 2px 2px 0;
            }
        }
    }

}
