@import "@/assets/scss/style.scss";

.addOfferTitlesWrapper {
    display: inline-block;

    &::v-deep {
        .buttonIcon {
            margin-right: 0 !important;
        }
    }
}

