@import "@/assets/scss/style.scss";

.ce-popup {
    &__message {
        font-size: 1.2rem;
        color: #2a5aa4;
        margin-bottom: 2rem;
    }

    &__trophy {
        display: block;
        text-align: center;
        font-size: 5rem;
        line-height: 5rem;
        transform: scale(0.5);
        animation: scaleCup 2s infinite alternate;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    &__confetti-wrapper {
        overflow: hidden !important;
        opacity: 0;
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        transition: opacity 0.5s ease-in;

        &--visible {
            opacity: 1;
        }
    }

    @keyframes scaleCup {
        0% {
            transform: scale(0.6);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes confettiRain {
        0% {
            opacity: 1;
            margin-top: -100vh;
            margin-right: -5vw;
        }

        25% {
            margin-right: 5vw;
        }

        50% {
            margin-right: -5vw;
        }

        75% {
            margin-right: 5vw;
        }

        100% {
            opacity: 1;
            margin-top: 100vh;
            margin-right: -5vw;
        }
    }
}

::v-deep .ant-modal-title {
    font-weight: bold;
}

::v-deep .ant-modal-wrap {
    overflow: hidden;
}

::v-deep .confetti {
    opacity: 0;
    position: absolute;
    animation: confettiRain 15s ease infinite;
    animation-play-state: running; /* Initially set to 'running' */
}

::v-deep .rectangle {
    height: 1rem;
    width: 1.5rem;
}

::v-deep .triangle {
    height: 1.2rem;
    width: 1.2rem;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
}

::v-deep .circle {
    height: 1.2rem;
    width: 1.2rem;
    clip-path: circle(50%);
}
