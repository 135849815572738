@import "@/assets/scss/style.scss";

.installationAndDeliveryDetailsForm {
    &::v-deep {
        .row {
            display: flex;
            margin-bottom: 24px;
            align-items: flex-end;

            & > * {
                flex: 1 1 33%;
                margin-right: 16px;

                &:last-child {
                    margin-right: 0;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media (max-width: 481px) {
    .installationAndDeliveryDetailsForm &::v-deep .row:not(.row-1) {
        flex-direction: column;

        & > * {
            margin-right: 0;
        }
    }
}
