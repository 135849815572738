@import "@/assets/scss/style.scss";

.orderNotesForm {
    .row {
        display: flex;
        margin-bottom: 30px;
        align-items: flex-end;

        &::v-deep {
            .ant-form-item-label {
                line-height: 1;
                margin-bottom: 4px;

                & > label {
                    @extend .u-b2--;
                    color: $grey-7;
                }
            }
        }

        & > * {
            flex: 1 1 33%;
            margin-right: 16px;
            margin-bottom: 0;

            &:last-child {
                margin-right: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .checkboxFormItem {
        border: 1px solid $grey-1;
        padding: 0 12px;

        &::v-deep {
            .ant-form-item-control {
                line-height: 38px;
            }
        }
    }
}
