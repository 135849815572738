@import "@/assets/scss/style.scss";

.skeletonWrapper {
    display: flex;

    .tabs {
        flex: 0 1 150px;
    }

    .content {
        display: flex;

        &::v-deep {
            .ant-skeleton-avatar {
                width: 92px;
                height: 84px;
                line-height: 84px;
            }
        }
    }
}

