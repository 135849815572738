@import "@/assets/scss/style.scss";

.custom-order {
    &__form {
        margin-bottom: 20px;
    }

    &__description {
        min-height: 5rem;
    }

    &__attachments {
        ::v-deep .ant-card-body {
            padding: 0 !important;
        }
    }
}
