@import "@/assets/scss/style.scss";

.icon-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 28px;
  width: 16px;

  &:hover {
    cursor: pointer;
    color: $mid-blue;
  }

  &__first {
    position: absolute;
    top: 0;
  }

  &__middle {
    position: absolute;
    top: 6px;
  }

  &__last {
    position: absolute;
    top: 12px;
  }
}
