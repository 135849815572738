@import "@/assets/scss/style.scss";

.productsActionsWrapper {
    display: flex;
    align-items: center;

    & > * {
        margin-right: 8px;
    }
}
