@import "@/assets/scss/style.scss";

.c-price-manipulation-form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > * {
        width: 100%;
        margin-right: 20px;
        padding: 0;

        &:last-child {
            margin-right: 0;
        }
    }

    &__delete-wrapper {
        flex: 0 1 32px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__delete {
        justify-self: flex-end;
        padding: 8px;
        border: 1px solid $grey-1;
        border-radius: 2px;
        color: $grey-9;
        cursor: pointer;
        background-color: transparent;
        transition: background-color 0.2s linear, color 0.2s linear;

        &:hover {
            background-color: $grey-0;
        }
    }

    &__item {
        &::v-deep {
            .ant-form-item-label {
                padding-bottom: 4px;

                label {
                    @extend .u-b1;
                    color: $grey-8;
                }
            }
        }
    }

    &__wrapper {
        padding: 24px;

        &.isLoading {
            opacity: 0.6;
            pointer-events: none;
        }
    }

    &__button {
        border-radius: 2px;
    }

    &__title {
        padding-bottom: 10px;
        display: inline-block;
    }

    &__edit-button {
        float: right;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        padding: 16px 24px;
        border-top: 1px solid $grey-4;

        & > * {
            margin-right: 4px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
