@import "@/assets/scss/style.scss";

.offerBottomBarWrapper {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 18px 36px;
    padding-left: 140px;
    z-index: 10;
    background-color: $white-two;
    box-shadow: inset 0 1px 0 #e2e4e9;
    left: 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100vw - 497px);

    @media (max-width: 992px) {
        display: none;
    }

    .detailsWrapper {
        flex-grow: 1;
        justify-content: flex-start;
        display: flex;
        align-items: flex-start;

        & > * {
            margin-right: 16px;
            min-width: 140px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .actionsWrapper {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .priceColumn {
            min-width: 120px;
            margin-right: 20px;
        }

        .button {
            margin-right: 4px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.offerBottomBarWrapper--responsive {
    padding-left: 120px;
    width: calc(100vw - 80px);
}
